import React, { useContext, useState } from "react"
import Layout from "../../components/Layout"
import { navigate } from "gatsby"
import SEO from "../../components/seo"
import Styles from "../../styles/welcome.module.scss";
import GeneralButton from "../../components/atoms/GeneralButton";
import UserTeamJoinOrCreateTemplate from "../../templates/UserTeamJoinOrCreateTemplate"
import { BACK_BUTTON_STR } from "../../constant/const";
import { WelcomeContext } from "../../context/WelcomeContext";
import { updateUserOnWelcome } from "../../apis/user";
import { useRedirectFromWelcome } from "../../hooks/useRedirectFromWelcome";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../state/app";

const headerOption = {
    headerTitle: "ようこそ",
    leftIcon: BACK_BUTTON_STR,
}

const UserTeamJoinPage = () => {

    useRedirectFromWelcome()
    const { teamDone, setTeamDone, name, answers, anotherAnswer, resetWelcomeContext } = useContext( WelcomeContext )
    const { userUuid } = useSelector( ( state: AppState ) => state.app )
    const [ updating, setUpdating ] = useState( false );
    const dispatch = useDispatch()

    const submit = async () => {
        if ( !userUuid ) return
        setUpdating( true )
        try {
            const answerStrings = [ ...answers.filter( ans => ans.isSelected ).map( ans => ans.label ), anotherAnswer ]
            const purposeString = answerStrings.join( "、" )
            const params = {
                last_name: name,
                purpose_for_this_service: purposeString
            }
            const { data } = await updateUserOnWelcome( { params, userID: userUuid } )
            setUpdating( false )
            resetWelcomeContext()
            dispatch( { type: "setHasCompletedRegistration", hasCompletedRegistration: true } )
            dispatch( { type: "setUserName", userName: data.data.name } )
            navigate( "/home" )
        }
        catch ( error ) {
            console.warn( error )
            setUpdating( false )
        }
    }

    return (
        < Layout headerOption={ headerOption } footerHide={ true } >
            <>
                <SEO title="User Team Join" />
                <div className={ Styles.container }>
                    <h1 className={ Styles.title } style={ { marginBottom: 40 } }>{ "3. チーム参加 / 作成" }</h1>
                    <div style={ { marginBottom: 80 } }>
                        { teamDone &&
                            <p style={ { marginTop: 12, fontSize: 16, color: "rgba(0,0,0,0.54)" } }>すでにチームに参加しています</p> }
                        { teamDone ?
                            <GeneralButton
                                style={ {
                                    width: 200,
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    borderRadius: 6,
                                    margin: "auto",
                                    marginTop: 40,
                                    border: "none"
                                } }
                                textStyle={ { fontSize: 20, color: "#fff", } }
                                title="始める"
                                loading={ updating }
                                onClick={ submit } /> :
                            <UserTeamJoinOrCreateTemplate
                                belongToTeam={ false }
                                onSucceeded={ () => setTeamDone( true ) } /> }
                        { !teamDone &&
                            <div style={ { textAlign: "center" } }>
                                <GeneralButton
                                    style={ {
                                        // width: 200,
                                        paddingTop: 12,
                                        paddingBottom: 12,
                                        margin: "auto",
                                        marginTop: 8,
                                        background: "rgba(0,0,0,0)",
                                        border: "none"
                                    } }
                                    textStyle={ { fontSize: 18, color: "#009ccc", } }
                                    title="とりあえず始める"
                                    loading={ updating }
                                    onClick={ submit } />
                            </div> }
                    </div>
                    <div className={ Styles.button_container }>
                        <GeneralButton
                            outline
                            title="戻る"
                            style={ { width: 200, } }
                            onClick={ () => navigate( "/welcome/survey" ) } />
                    </div>
                </div>
            </>
        </Layout >
    )
}

export default UserTeamJoinPage
